<template>
  <div class="card card-custom gutter-b card-stretch min-h-100">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <div class="card-title text-left">
        <div class="card-label font-weight-bolder text-dark-75">
          <span v-html="title" />
          <div class="font-size-sm text-muted mt-2" v-html="subtitle" />
        </div>
      </div>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column py-0 min-h-100">
      <div class="row">
        <!--begin::Chart-->
        <apexchart
          class="col-md-12 mt-4 p-0"
          type="line"
          height="280"
          :options="lineOptions"
          :series="lineSeries"
        />
        <!--end::Chart-->
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "AvgMaxRisk",
  props: {
    series: {
      required: true,
      type: Array
    },
    startDate: {
      required: true
    },
    discrete: {
      type: Array,
      required: true
    },
    base: {
      type: Number,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("Auth", ["hasModule"]),
    betweenMonths() {
      var startDate = this.startDate;
      var endDate = moment();
      var betweenMonths = [];

      if (moment(startDate).isBefore(endDate)) {
        var date = moment(startDate).startOf("month");

        while (date < endDate.endOf("month")) {
          betweenMonths.push(moment(date));
          date.add(1, "month");
        }
      }
      return betweenMonths;
    },
    lineSeries() {
      let series = _.filter(
        this.series,
        serie =>
          serie.service == undefined ||
          (this.hasModule([serie.service]) && serie.data?.length > 0)
      );

      let equalSeries = [];

      _.each(series, serie => {
        let newSerie = _.clone(serie);
        let data = _.clone(serie.data);
        _.each(this.betweenMonths, month => {
          if (!_.find(data, d => _.first(d).diff(month, "days") == 0)) {
            data.push([month, null]);
          }
        });
        newSerie.data = data;
        equalSeries.push(newSerie);
      });

      return equalSeries;
    },
    lineOptions() {
      return {
        chart: {
          height: 300,
          stacked: false,
          type: "line",
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: false,
              customIcons: []
            }
          }
        },
        colors: [_.map(this.lineSeries, "color")],
        dataLabels: {
          enabled: false
        },
        stroke: {
          lineCap: "round",
          opacity: 0.75,
          width: [3, 2, 2, 2, 2, 2, 2, 2],
          curve: "smooth"
        },
        legend: {
          show: true,
          showForNullSeries: true,
          showForZeroSeries: true,
          onItemClick: {
            toggleDataSeries: false,
            highlightDataSeries: true
          },
          fontSize: "14px",
          fontWeight: 600
        },
        fill: {
          opacity: [0.1, 1, 1, 1, 1, 1, 1, 1]
        },
        xaxis: {
          type: "datetime",
          tickAmount: 8,
          min: this.startDate,
          max: moment(),
          categories: this.betweenMonths,
          labels: {
            rotate: -45,
            rotateAlways: true,
            labels: {
              format: "MM yyyy"
            },
            formatter: function(val, timestamp) {
              return moment(new Date(timestamp)).format("DD MMM YYYY");
            }
          }
        },
        yaxis: {
          min: 0,
          max: this.base,
          tickAmount: this.base,
          labels: {
            formatter: value =>
              this.$n(value || 0, { maximumFractionDigits: 1 })
          }
        },
        tooltip: {
          shared: true
        },
        grid: {
          show: false
        },
        markers: {
          discrete: this.discrete
        }
      };
    }
  }
};
</script>

<style lang="scss">
.apexcharts-legend.position-left {
  text-align: left;
}
.inner-text {
  &--1 {
    padding-top: 34%;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    width: 100%;
    height: 100%;
    .small {
      font-size: 1rem;
      top: 2rem;
    }
  }
  .inner-title {
    width: 100%;
    text-align: center;
    font-size: 0.75rem;
  }
}
</style>
