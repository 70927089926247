var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.score)?_c('div',{staticClass:"card card-custom gutter-b card-stretch-half"},[_c('div',{staticClass:"card-body d-flex"},[_c('VueSvgGauge',{attrs:{"start-angle":-110,"end-angle":110,"value":_vm.score,"separator-step":1,"min":0,"max":_vm.base,"inner-radius":60,"gauge-color":[
        {
          offset: 0,
          color: _vm.getColorByValueAndBase(this.score.toFixed(0), _vm.base, true)
        },
        {
          offset: 100,
          color: _vm.getColorByValueAndBase(this.score.toFixed(0) + 1, _vm.base, true)
        }
      ],"scale-interval":1}},[_c('div',{staticClass:"inner-text inner-text--1"},[(_vm.score >= 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$n(_vm.score.toFixed(1)))+" ")]):_c('span',[_vm._v("?")]),_c('span',{staticClass:"small"},[_vm._v("/"+_vm._s(_vm.base))]),_c('div',{staticClass:"inner-title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.title)}})])])])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }