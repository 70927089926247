<template>
  <div class="card card-custom gutter-b card-stretch-half" v-if="score">
    <div class="card-body d-flex ">
      <VueSvgGauge
        :start-angle="-110"
        :end-angle="110"
        :value="score"
        :separator-step="1"
        :min="0"
        :max="base"
        :inner-radius="60"
        :gauge-color="[
          {
            offset: 0,
            color: getColorByValueAndBase(this.score.toFixed(0), base, true)
          },
          {
            offset: 100,
            color: getColorByValueAndBase(this.score.toFixed(0) + 1, base, true)
          }
        ]"
        :scale-interval="1"
      >
        <div class="inner-text inner-text--1">
          <span v-if="score >= 0">
            {{ $n(score.toFixed(1)) }}
          </span>
          <span v-else>?</span>
          <span class="small">/{{ base }}</span>
          <div class="inner-title">
            <span v-html="title" />
          </div>
        </div>
      </VueSvgGauge>
    </div>
  </div>
</template>

<script>
import { VueSvgGauge } from "vue-svg-gauge";
import { getColorByValueAndBase } from "@/utils/helpers";

export default {
  name: "KymatioScore",
  components: {
    VueSvgGauge
  },
  props: {
    score: {
      type: Number,
      required: true
    },
    base: {
      type: Number,
      required: false,
      default: 10
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    getColorByValueAndBase(value, base, hex) {
      return getColorByValueAndBase(value, base, hex) + "aa";
    }
  }
};
</script>

<style lang="scss" scoped>
.gauge {
  height: 80%;
  width: 80%;
  margin: auto;
}
</style>
