<template>
  <div
    :class="
      'card card-custom' +
        (!visibility ? ' bg-gray-200' : '') +
        (half ? ' card-stretch-half' : ' card-stretch')
    "
  >
    <!--begin::Body-->
    <div
      class="card-body justify-content-center d-flex align-items-start flex-column"
    >
      <!--begin::Icon-->
      <!--begin::Follower-->
      <div class="mx-auto">
        <span class="font-weight-bold font-size-h7 text-muted">
          {{ $t(`dashboard.status.${title}`) }}
        </span>
      </div>
      <!--end::Follower-->
      <div class="mx-auto mt-3">
        <!--begin::Svg Icon | path: icons/duotune/maps/map004.svg-->
        <span
          :class="
            `svg-icon svg-icon-4x svg-icon-gray-600` +
              (!visibility ? '' : ` svg-icon-${iconClass}`)
          "
        >
          <simple-svg :src="require(`@/assets/images/icons/${icon}`)" />
        </span>
        <!--end::Svg Icon-->
      </div>
      <!--end::Icon-->
      <!--begin::Section-->
      <div class="d-flex flex-column mt-3 mx-auto">
        <!--begin::Number-->
        <span
          :class="
            'font-weight-bolder display-4 lh-1 ls-n2' +
              (!visibility ? ' text-muted' : ' text-gray-800')
          "
          v-if="!percentage"
        >
          {{
            visibility && punctuation !== false
              ? $n(punctuation, { maximumFractionDigits: 2 })
              : "-"
          }}
          <span class="h2">/{{ base }}</span>
        </span>
        <span
          class="d-flex font-weight-bolder display-4 text-gray-800 lh-1 ls-n2"
          v-else
        >
          {{ $n(punctuation / 100, "percent") }}
        </span>
        <!--end::Number-->
      </div>
      <!--end::Section-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { SimpleSVG } from "vue-simple-svg";
export default {
  name: "Punctuation",
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    punctuation: {
      required: true
    },
    percentage: {
      type: Boolean,
      required: false,
      default: false
    },
    iconClass: {
      type: String,
      required: false,
      default: ""
    },
    visibility: {
      required: false,
      default: false
    },
    half: {
      type: Boolean,
      required: false,
      default: false
    },
    base: {
      type: Number,
      required: false,
      default: 10
    }
  },
  components: {
    "simple-svg": SimpleSVG
  },
  computed: {
    classType() {
      return this.difference === 0
        ? "secondary"
        : this.difference > 0
        ? "success"
        : "danger";
    }
  }
};
</script>
